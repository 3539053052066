import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import ecommerceStoreModule from '@/views/shop/eCommerceStoreModule'
import auth from './app/auth';
// import notification from "./app/notification";

// push notifications
// notification.actions.connectPushNotifications().then(r => console.log(r));

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiDomain: '',
    adminDomain: process.env.VUE_APP_DOMAIN_ADMIN,
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    auth,
    // notification,
  },
  strict: process.env.DEV,
})
