import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMobileDetection from 'vue-mobile-detection'
import VueGtm from 'vue-gtm'

import { NavigationRoute, registerRoute } from 'workbox-routing'
import { NetworkOnly } from 'workbox-strategies'
import * as navigationPreload from 'workbox-navigation-preload'
import router from './router'
import store from './store'
import App from './App.vue'
import Store from "./store"
import VueMeta from 'vue-meta'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import wb from './registerServiceWorker'

const CACHE_NAME = 'offline-html'
// This assumes /offline.html is a URL for your self-contained
// (no external images or styles) offline page.
const FALLBACK_HTML_URL = '/offline.html'

// echo
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

Vue.prototype.$echo = new Echo({
  authEndpoint: process.env.VUE_APP_DOMAIN_API + '/api/broadcasting/auth',
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  wsHost: process.env.VUE_APP_PUSHER_HOST,
  wsPort: process.env.VUE_APP_PUSHER_PORT,
  forceTLS: false,
  disableStats: true,
  auth: {
    headers: {
      withCredentials: false,
      Authorization: 'Bearer ' + Store.getters.accessToken,
    },
  },
});

// Populate the cache with the offline HTML page when the
// service worker is installed.
if (wb !== null) {
  wb.addEventListener('install', async event => {
    event.waitUntil(
      caches.open(CACHE_NAME)
        .then(cache => cache.add(FALLBACK_HTML_URL)),
    )
  })

  navigationPreload.enable()

  const networkOnly = new NetworkOnly()
  const navigationHandler = async params => {
    try {
      // Attempt a network request.
      return await networkOnly.handle(params)
    } catch (error) {
      // If it fails, return the cached HTML.
      return caches.match(FALLBACK_HTML_URL, {
        cacheName: CACHE_NAME,
      })
    }
  }

  // Register this strategy to handle all navigations.
  registerRoute(
    new NavigationRoute(navigationHandler),
  )
}

Vue.use(VueMobileDetection)

Vue.prototype.$workbox = wb

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueMeta)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueGtm, {
  id: 'GTM-TC766MN',
  enabled: true,
  debug: false,
  vueRouter: router,
  ignoredViews: [],
})

require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
