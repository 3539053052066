import axios from "axios";

const state = {
    user: axios.get(process.env.VUE_APP_DOMAIN_API+`/api/me`,{
        headers: {
            Authorization : `Bearer ${localStorage.getItem('accessToken')}`
        }
    }),
};

const getters = {
    isAuthenticated: state => !!state.user,
    isConfirmed: state => state.user.confirmed,
    StateUser: state => state.user,
    accessToken: () => localStorage.getItem('accessToken'),
};

const actions = {
    async Register({dispatch}, form) {
        await axios.post('/auth/register', form)
        let UserForm = new FormData()
        UserForm.append('username', form.username)
        UserForm.append('password', form.password)
        await dispatch('LogIn', UserForm)
    },

    async LogIn({commit}, user) {
        await axios.post("/auth/login", user);
        await commit("setUser", user.get("username"));
    },

    async LogOut({ commit }) {
        let user = null;
        commit("logout", user);
    },
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },

    logout(state, user) {
        state.user = user;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
